import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import Logo from "@assets/images/Logo";
import Constants from "expo-constants";
import { colors } from "@styles/globalStyles";

interface Props {}

interface State {}

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Logo style={{ marginTop: 50, marginBottom: -50 }} width={400} height={400} />
                {/* <BoldText fontSize={22} style={{ marginBottom: 5 }}>
                    {t("welcome_to")}{" "}
                    <BoldText fontSize={22} color={colors.labelText}>
                        {" "}
                        {"SURA GAMING"}
                    </BoldText>
                </BoldText> */}
                <RegularText align="center" fontSize={18}>
                    El mayor Ecosistema de{" "}
                    <BoldText align="center"   fontSize={18}>
                        Gaming
                    </BoldText>{" "}
                    🎮
                </RegularText>
                <RegularText align="center"   fontSize={18}>
                    y{" "}
                    <BoldText align="center" fontSize={18}>
                        Web3
                    </BoldText>{" "}
                    de Hablahispana
                </RegularText>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
